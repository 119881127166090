// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-articlepage-js": () => import("./../../src/templates/blog_articlepage.js" /* webpackChunkName: "component---src-templates-blog-articlepage-js" */),
  "component---src-templates-blog-catalogpage-js": () => import("./../../src/templates/blog_catalogpage.js" /* webpackChunkName: "component---src-templates-blog-catalogpage-js" */),
  "component---src-templates-blog-listpage-js": () => import("./../../src/templates/blog_listpage.js" /* webpackChunkName: "component---src-templates-blog-listpage-js" */)
}

